import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { WrappedInput } from './WrappedInput';
import LoginBox from './Login';
import SignUpBox from './SignUp';
import ForgotPassword from './ForgotPassword';

import { toast } from 'react-toastify';
import { useAuth } from '../hooks/useAuth';

import './Login.css';

import bgImg from '../assets/images/login-img.png';
import Logo from '../assets/images/logo/logo.png';
import Emitter from './Emitter';
import CustomerService from './CustomerService';

function LoginSignUpBox() {
  const { signOut } = useAuth();

  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  const [showModal, setShowModal] = useState(false);

  const toggleLoginModalTab = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const closeLoginBox = () => {
    setShowModal(false);
  };

  const logout = () => {
    setShowModal(false);
    signOut().then(function () {
      toast.info('Successful  logged out');
    });
  };
  const showLogin = (data) => {
    setShowModal(true);
    setCurrentActiveTab('1');
  };

  const showSignUp = (data) => {
    setShowModal(true);
    setCurrentActiveTab('2');
  };

  const userSignedUp = () => {
    closeLoginBox();
    toast.info('Sign up successful kindly check your email for OTP verification');
  };

  const passwordReset = () => {
    closeLoginBox();
    toast.info(
      'Password reset request send successfully to your registered email. Please check your email for verification'
    );
  };
  const showForgotPassword = () => {
    setShowModal(true);
    setCurrentActiveTab('3');
  };
  const showCustomerService = () => {
    setShowModal(true);
    setCurrentActiveTab('4');
  };

  useEffect(() => {
    Emitter.on('show_login', showLogin);
    Emitter.on('show_signup', showSignUp);
    Emitter.on('show_forgot_password', showForgotPassword);
    Emitter.on('userLoggedIn', closeLoginBox);
    Emitter.on('signOut', logout);
    Emitter.on('user_signedup', userSignedUp);
    Emitter.on('password_reset', passwordReset);
    Emitter.on('customer_service', showCustomerService);
    Emitter.on('close_customer_service', closeLoginBox);
    return () => {
      Emitter.off('show_login', showLogin);
      Emitter.off('show_signup', showSignUp);
      Emitter.off('show_forgot_password', showForgotPassword);
      Emitter.off('userLoggedIn', closeLoginBox);
      Emitter.off('user_signedup', userSignedUp);
      Emitter.off('signOut', logout);
      Emitter.off('password_reset', passwordReset);
    };
  }, []);

  const [loginModal, setLoginModal] = useState({
    showModal: false,
    loading: false,
  });

  function closeLoginModal() {
    setShowModal(false);
  }

  return (
    <div>
      <Modal isOpen={showModal} toggle={closeLoginModal} className={'authModal modal-dialog-centered'}>
        {/* <ModalHeader toggle={closeLoginBox}></ModalHeader> */}
        <ModalBody>
          <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1">
              <div className="loginMain">
                <img src={bgImg} className="bgLogoImg" alt="" />

                <div className="col-md-12 text-center">
                  <img src={Logo} className="img-fluid logoModal" alt="" />
                  <h3 className="mt-3 text-white loginText">Login</h3>
                  <div style={{ margin: '0 5rem' }}>
                    <p className={'mt-3 me-1 ms-1'} style={{ fontSize: '12px' }}>
                      For the purpose of industry regulation, your details are required.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-3 border-bottom"></div>

              <LoginBox setCurrentActiveTab={setCurrentActiveTab} currentActiveTab={currentActiveTab} />

              <div className="form-group mt-2">
                <p className="text-center notAccount">
                  Don't have an account?{' '}
                  <a
                    className={'cursor-pointer'}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      toggleLoginModalTab('2');
                    }}
                    style={{ color: '#0497FE' }}
                  >
                    Sign up
                  </a>
                </p>
              </div>
            </TabPane>
            <TabPane tabId="2" style={{ padding: '0rem' }}>
              <div className="loginMain">
                <img src={bgImg} className="bgLogoImg" alt="" />

                <div className="col-md-12 text-center">
                  <img src={Logo} className="img-fluid logoModal" height={43} width={195} alt="" />
                  <h3 className="mt-3 text-white loginText">Sign up</h3>
                  <div style={{ margin: '0 5rem' }}>
                    <p className={'mt-3 me-1 ms-1'} style={{ fontSize: '12px' }}>
                      For the purpose of industry regulation, your details are required.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-3 border-bottom"></div>
              <SignUpBox />

              <div className="form-group mt-2 mb-4">
                <p className="text-center">
                  <a
                    className={'cursor-pointer  '}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleLoginModalTab('1');
                    }}
                    style={{ color: 'black', fontSize: '12px' }}
                  >
                    Already have an account?
                  </a>
                  <label
                    onClick={(e) => {
                      e.preventDefault();
                      toggleLoginModalTab('1');
                    }}
                    style={{ color: '#0497FE', fontSize: '12px', marginLeft: '0.3rem', cursor: 'pointer' }}
                  >
                    Login
                  </label>
                </p>
              </div>
            </TabPane>
            <TabPane tabId="3">
              <ForgotPassword />
            </TabPane>
            <TabPane tabId="4">
              <CustomerService />
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default LoginSignUpBox;
