import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormFeedback } from 'reactstrap';
import { WrappedNumberInput } from './WrappedNumberInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosUnSecureInstance } from '../services/axio-service';
import Loader from './Loader';
import { yupResolver } from '@hookform/resolvers/yup';
import { otpValidationSchema } from '../validation';
import '../assets/css/otp.css';

const OTPComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(otpValidationSchema),
  });

  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const location = useLocation();
  const {
    userId,
    //  phone_number,
    email,
  } = location.state || {};

  console.log(
    'userId',
    userId,
    //  phone_number,
    email
  );

  useEffect(() => {
    if (
      !userId &&
      //  !phone_number
      !email
    ) {
      navigate('/');
    }
  }, [
    userId,
    //  phone_number,
    email,
    navigate,
  ]);

  const onSubmit = async (data) => {
    // console.log('data', data);
    // let newData = { otpCode: data.otp, userId: userId };

    try {
      setisLoading(true);
      // let res = await axiosUnSecureInstance.post('accounts/signup/verify/otp/', newData);

      let emailBody = { code: data?.emailCode, userId: userId };

      const emailVerify = await axiosUnSecureInstance.post('/accounts/signup/verify/', emailBody);

      if (emailVerify) {
        toast.success('Email Verification Successful');
      }

      // if (res) {
      //   toast.success('Phone Number Verification Successful');
      // }
      if (emailVerify) {
        navigate('/');
      }
    } catch (error) {
      if (error.response.data.emailError) {
        toast.error(error.response.data.emailError);
      } else {
        toast.error(error.response.data.message);
      }
    } finally {
      setisLoading(false);
    }
  };

  const resendOTP = async () => {
    let newData = {
      //  phone_number: phone_number,
      user_id: userId,
    };
    try {
      setisLoading(true);

      let res = await axiosUnSecureInstance.post('accounts/signup/resend/otp/', newData);
      if (res?.data?.message) {
        toast.success(res.data.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setisLoading(false);
    }
  };

  const resendEmailOTP = async () => {
    let newData = { email: email, user_id: userId };
    try {
      setisLoading(true);

      let res = await axiosUnSecureInstance.post('accounts/signup/resend-email/otp/', newData);
      if (res.data.message) {
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log('error', error.response.data.message);
      toast.error(error.response.data.message);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <div
      className="otpFormWrapper"
      style={{
        backgroundColor: '#F8FAFC',
      }}
    >
      {isLoading && <Loader />}
      <div className="otpForm">
        <form className="mainForm">
          <h2 className="otpMainTitle">OTP Verification</h2>
          <p className="otpSubTitle">Enter the 6-digit verification code that was sent to your email.</p>
          {/* <p>{phone_number}</p> */}

          {/* <WrappedNumberInput type="number" name="otp" registerhandler={register} placeholder="Enter SMS OTP" />
          {errors.otp && (
            <p
              className="errorMsg"
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              {errors.otp.message}
            </p>
          )} */}
          <div className="mt-4"></div>
          <WrappedNumberInput type="number" name="emailCode" registerhandler={register} placeholder="Enter Email OTP" />
          {errors.emailCode && (
            <p
              className="errorMsg"
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              {errors.emailCode.message}
            </p>
          )}

          <button className="OTPsubmitBtn" onClick={handleSubmit(onSubmit)}>
            Submit
          </button>
          {/* <p className="resendOTP">
            Didn't receive SMS code?{' '}
            <span className="cursor-pointer resendOTPSpan" onClick={resendOTP}>
              Resend
            </span>
          </p> */}
          <p className="resendOTP">
            Didn't receive Email code?{' '}
            <span className="cursor-pointer resendOTPSpan" onClick={resendEmailOTP}>
              Resend
            </span>
          </p>
        </form>
      </div>
    </div>
  );
};

export default OTPComponent;
