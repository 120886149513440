import { Link, useLocation, useParams } from 'react-router-dom';

import loginIcon from '../assets/images/login.png';
import signUpIcon from '../assets/images/signup.png';
import userIcon from '../assets/images/userIcon.png';
import Emitter from '../components/Emitter';
import { useAuth } from '../hooks/useAuth';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useEffect, useState } from 'react';
import { AUTH_CONSTANTS } from '../store/constant';
import axiosInstance from '../services/axio-service';
import { useNavigate } from 'react-router-dom';
import { useRemainingReportState } from '../hooks/useRemainingReportSate';

function Header() {
  const { user, isSuperUser ,fullName} = useAuth();
  const { remainingReports, setRemainingReport } = useRemainingReportState();

  const navigate = useNavigate();

  const { id, read } = useParams();
  const { pathname } = useLocation();
  // const [remainingReports, setRemainingReport] = useState(null);

  const userInfo = JSON.parse(localStorage.getItem(AUTH_CONSTANTS.USER_DATA));

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getRemainingReport = async () => {
    try {
      const res = await axiosInstance.get('subscribed-product/');

      setRemainingReport(res?.data?.plan);
    } catch (error) {
      setRemainingReport(null);
    }
  };

  useEffect(() => {
    if (user) {
      getRemainingReport();
    }
  }, [user]);

  return (
    <>
      {(user && pathname == '/vendor') ||
      (user && pathname == '/leads') ||
      (user && pathname == `/lead-detail/${id}/${read}`) ||
      (user && pathname == '/profile') ? (
        ''
      ) : (
        <div className="top-nav">
          <div className="container d-flex justify-content-end">
            {!user && (
              <ul className="my-2 topnav">
                <li>
                  <Link
                    onClick={(e) => {
                      Emitter.emit('show_login');
                      e.preventDefault();
                      e.stopPropagation();
                      return false;
                    }}
                  >
                    <img src={loginIcon} />
                    Login
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={(e) => {
                      Emitter.emit('show_signup');
                      e.preventDefault();
                      e.stopPropagation();
                      return false;
                    }}
                  >
                    <img src={signUpIcon} />
                    Sign Up
                  </Link>
                </li>
                <button
                  onClick={() => {
                    Emitter.emit('customer_service');
                  }}
                  className="self-center bg-transparent text-white underline  p-1 text-xs h-fit w-fit"
                >
                  Customer Service
                </button>
              </ul>
            )}
            {user && (
              <Dropdown className="dropdownHeader" isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle className="align-items-center d-flex gap-1">
                  {' '}
                  <img src={userIcon} /> <span>{fullName}</span>{' '}
                </DropdownToggle>
                <DropdownMenu className="dropdownMenuHeader  ">
                  {userInfo?.user_type == 'VENDOR' && (
                    <div
                      className="flex"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Link to="/profile">
                        <DropdownItem className="dropdownMenuHeaderItem">My Profile</DropdownItem>
                      </Link>
                      <Link to="/leads">
                        <DropdownItem className="dropdownMenuHeaderItem">Leads</DropdownItem>
                      </Link>
                    </div>
                  )}

                  {isSuperUser ||
                    (userInfo?.user_type == 'VENDOR' ? (
                      ''
                    ) : (
                      <DropdownItem className="dropdownMenuHeaderItem">
                        Remaining Reports {remainingReports}
                      </DropdownItem>
                    ))}

                  {isSuperUser && (
                    <Link to="/vendor">
                      <DropdownItem className="dropdownMenuHeaderItem">Vendor</DropdownItem>
                    </Link>
                  )}
                  {isSuperUser && (
                    <DropdownItem
                      className="dropdownMenuHeaderItem"
                      onClick={() => {
                        window.open(process.env.REACT_APP_SERVER_BASE_URL + '/api/admin', '_blank', 'noreferrer');
                      }}
                    >
                      Admin{' '}
                    </DropdownItem>
                  )}
                  {isSuperUser && (
                    <DropdownItem
                      className="dropdownMenuHeaderItem"
                      onClick={() => {
                        window.open(
                          process.env.REACT_APP_SERVER_BASE_URL + '/api/admindashboard',
                          '_blank',
                          'noreferrer'
                        );
                      }}
                    >
                      Dashboard{' '}
                    </DropdownItem>
                  )}
                  <DropdownItem
                    className="dropdownMenuHeaderItem"
                    onClick={(e) => {
                      // Emitter.emit('signOut');
                      // e.preventDefault();
                      // e.stopPropagation();
                      navigate('/user-profile');
                      return false;
                    }}
                  >
                    Profile
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownMenuHeaderItem"
                    onClick={(e) => {
                      Emitter.emit('signOut');
                      e.preventDefault();
                      e.stopPropagation();
                      navigate('/');
                      return false;
                    }}
                  >
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              // <ul>
              //   <li>
              //     <Link
              // onClick={(e) => {
              //   Emitter.emit('signOut');
              //   e.preventDefault();
              //   e.stopPropagation();
              //   return false;
              // }}
              //     >
              //       <img src={logoutIcon} />
              //       Log Out
              //     </Link>
              //   </li>
              // </ul>
            )}
            {user && (
              <button
                onClick={() => {
                  Emitter.emit('customer_service');
                }}
                className="self-center bg-transparent text-white underline  p-1 text-xs h-fit w-fit"
              >
                Customer Service
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
